import BaseController from '@/service/BaseService'
import WebsiteRequest from '@/request/Business/WebsiteRequest'
import { errorTip } from '@/utils/Tip'

const base = new BaseController()

export default {
  data () {
    return {
      showDialog: false, // 是否显示弹窗
      tableLoad: false, // 表格加载
      scene: 0, // 场景: 0表示弹框为添加，1表示弹框为修改
      active: 1,
      contact: [],
      tableForKey: true,
      // 表单信息
      form: {
        titleCn: '',
        address: '',
        templateId: 9,
        color: '#409EFF',
        priceType: 1,
        bannerArray: [],
        cityArray: [],
        teacherId: '',
        submissionDeadline: '',
        registrationDeadline: '',
        conferenceDate: '',
        status: 1,
        // 网站介绍
        about: '',
        // 会议委员会
        conferenceCommittee: '',
        // 项目委员会
        programCommittee: '',
        // 技术委员会
        internationalTechnicalCommittee: '',
        // 调用文件
        callPapers: ''
      },
      // 表单验证规则
      rules: {
        color: [
          {
            required: true,
            message: '请选择主题颜色'
          }],
        cityArray: [
          {
            required: true,
            message: '请选择地址'
          }],
        address: [{
          required: true,
          message: '请输入会议地址'
        }],
        teacherId: [{
          required: true,
          message: '请选择老师'
        }],
        submissionDeadline: [{
          required: true,
          message: '请输入提交截止日期'
        }],
        registrationDeadline: [{
          required: true,
          message: '请输入注册截止日期'
        }],
        conferenceDate: [{
          required: true,
          message: '请输入会议时间'
        }],
        about: [{
          required: true,
          message: '请输入简介'
        }],
        conferenceCommittee: [{
          required: true,
          message: '请输入会议委员会'
        }],
        programCommittee: [{
          required: true,
          message: '请输入项目委员会'
        }],
        internationalTechnicalCommittee: [{
          required: true,
          message: '请输入技术委员会'
        }],
        callPapers: [{
          required: true,
          message: '请输入征稿主题'
        }],
        status: [{
          required: true,
          message: '请输入状态'
        }]
      },

      // 分页信息[前端控制单页数量]
      pageData: {
        page: 1,
        limit: 10,
        total: 0,
        list: [],
        select: {
          price_type: {},
          status: {},
          template: {},
          teacher: {}
        }
      },

      // 表单筛选
      searchForm: {
        priceType: null,
        domain: null,
        status: null,
        teacherId: null,
        identifying: null,
        expired: null,
        createTimeArray: []
      }
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new WebsiteRequest()).getList(this.pageData, this.searchForm)
  },
  methods: {
    changeTemplate: function (va) {
      // console.log(1111)
      if (va !== 11) {
        this.form.color = ''
      }
    },
    // 输入日期改变
    changeDate: function (va) {
      const startDate = (va === 1)
        ? new Date(this.form.submissionDeadline)
        : new Date(this.form.submissionDeadline)
      // 检查日期是否有效
      if (isNaN(startDate)) errorTip(this, '请输入正确的日期格式')
      startDate.setDate(startDate.getDate() + 7)
      if (va === 1) {
        this.form.registrationDeadline = startDate.toISOString().substring(0, 10)
        startDate.setDate(startDate.getDate() + 7)
        this.form.conferenceDate = startDate.toISOString().substring(0, 10)
      } else {
        this.form.conferenceDate = startDate.toISOString().substring(0, 10)
      }
    },
    previousStepClick: function () {
      this.active -= 1
    },
    nextStepClick: function () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.active += 1
        }
      })
    },
    // 导入事件
    importClick: async function ({ file }) {
      await base.importClick(file)
    },
    // 导出事件
    exportClick: async function () {
      await base.exportClick()
    },
    // 提交事件
    submitClick: async function () {
      await base.submitClick()
      this.active = 1
    },
    // 取消事件
    cancelDialog: async function () {
      this.active = 1
      await base.cancelDialog()
    },
    // 删除事件
    deleteClick: async function (item) {
      await base.deleteClick(item)
    },
    // 搜索事件
    searchClick: async function () {
      this.pageData.page = 1
      await base.getList()
    },
    // 重置事件
    resetClick: async function () {
      await base.resetClick()
    },
    // 添加事件
    addClick: function () {
      base.addClick()
    },
    // 分页事件
    pagingClick: async function (val) {
      await base.pagingClick(val)
    },
    // 编辑事件
    editClick: async function (item) {
      await base.editClick(item)
    },
    // 子组件修改父组件data
    changDataValue: function (field, value) {
      this.form[field] = value
    }
  }
}
