<template>
  <div class="manage">

    <!--    搜索区域开始   -->
    <el-collapse>
      <el-collapse-item title="搜索" name="1">
        <el-form :model="searchForm"
                 :inline="true"
                 label-width="80px">
          <el-form-item label="价格类型">
            <el-select v-model="searchForm.priceType" placeholder="请选择价格类型">
              <el-option
                v-for="(item, index) in pageData.select.price_type"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="搜索老师">
            <el-select v-model="searchForm.teacherId" placeholder="请选择老师">
              <el-option
                v-for="(item, index) in pageData.select.teacher"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="网站域名">
            <el-input placeholder="请输入网站域名" v-model="searchForm.domain"/>
          </el-form-item>
          <el-form-item label="会议简称">
            <el-input placeholder="请输入会议简称" v-model="searchForm.identifying"/>
          </el-form-item>
          <el-form-item label="站点状态">
            <el-select v-model="searchForm.status" placeholder="请选择站点状态">
              <el-option
                v-for="(item, index) in pageData.select.status"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="会议过期">
            <el-select v-model="searchForm.expired" placeholder="请选择站点状态">
              <el-option label="未过期" value="1"/>
              <el-option label="过期" value="2"/>
            </el-select>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <!--    搜索区域结束   -->

    <!--    分割线-->
    <el-divider></el-divider>

    <!-- 弹窗区域开始 -->
    <el-dialog
      :title="`${form.titleCn} < ${form.identifying} >`"
      :visible.sync="showDialog"
      width="90%"
      :close-on-click-modal="false"
      :center="true"
      :before-close="cancelDialog">
      <el-steps :active="active" finish-status="success" :key="new Date().getTime()">
        <el-step title="基础配置"></el-step>
        <el-step title="图片组"></el-step>
        <el-step title="文本组"></el-step>
      </el-steps>

      <span class="footer">
          <el-button @click="cancelDialog" v-if="active === 1">取 消</el-button>
          <el-button @click="previousStepClick" v-if="active !== 1">上一步</el-button>
          <el-button @click="nextStepClick" v-if="active !== 3">下一步</el-button>
          <el-button type="primary" @click="submitClick" v-if="active === 3">确 定</el-button>
      </span>
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :model="form"
        label-width="auto"
        :rules="rules">
        <div v-if="active === 1">
          <el-form-item label="价格类型" prop="priceType">
            <el-select v-model="form.priceType" placeholder="请选择价格类型" style="width: 100%;">
              <el-option v-for="(item, index) in pageData.select.price_type"
                         :key="index"
                         :label="item"
                         :value="index*1"/>
            </el-select>
          </el-form-item>

          <el-form-item label="会议地址" prop="address">
            <el-input placeholder="请输入会议地址"
                      v-model="form.address"/>
          </el-form-item>
          <el-form-item label="老师" prop="teacherId">
            <el-select v-model="form.teacherId" placeholder="请选择价格类型" style="width: 100%;">
              <el-option v-for="(item, index) in pageData.select.teacher"
                         :key="index"
                         :label="item"
                         :value="index*1"/>
            </el-select>
          </el-form-item>

          <el-form-item label="截稿日期" prop="submissionDeadline">
            <el-input placeholder="请输入截稿日期"
                      v-model="form.submissionDeadline"
                      @change="changeDate(1)"/>
          </el-form-item>

          <el-form-item label="注册日期" prop="registrationDeadline">
            <el-input placeholder="请输入注册日期"
                      v-model="form.registrationDeadline"
                      @change="changeDate(2)"/>
          </el-form-item>

          <el-form-item label="会议时间" prop="conferenceDate">
            <el-input placeholder="请输入会议时间" v-model="form.conferenceDate"/>
          </el-form-item>

          <el-form-item label="状态" prop="status">
            <el-select v-model="form.status" placeholder="请选择状态" style="width: 100%;">
              <el-option v-for="(item, index) in pageData.select.status"
                         :key="index"
                         :label="item"
                         :value="index*1"/>
            </el-select>
          </el-form-item>

        </div>

        <div v-if="active === 2">
          <el-form-item label="模板" prop="templateId">
            <el-select v-model="form.templateId" placeholder="请选择价格类型" style="width: 100%;" @change="changeTemplate">
              <el-option v-for="(item, index) in pageData.select.template"
                         :key="index"
                         :label="item"
                         :value="index*1"/>
            </el-select>
          </el-form-item>

          <el-form-item v-if="form.templateId === 11" label="主题颜色" prop="color">
            <el-color-picker v-model="form.color"></el-color-picker>
          </el-form-item>
          <el-form-item label="轮播图" prop="bannerArray">
            <McUpload :limit="10"
                      field="bannerArray"
                      :files="form.bannerArray" type="image"
                      @event="changDataValue"/>
          </el-form-item>

          <el-form-item label="地图" prop="cityArray">
            <McMapScreenshot :city="form.cityArray" :key="new Date().getTime()"/>
          </el-form-item>
        </div>

        <div v-if="active === 3">
          <el-form-item label="网站介绍" prop="about">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5}"
              placeholder="请输入网站介绍"
              v-model="form.about"/>
          </el-form-item>

          <el-form-item label="会议委员会" prop="conferenceCommittee">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5}"
              placeholder="请输入会议委员会"
              v-model="form.conferenceCommittee"/>
          </el-form-item>

          <el-form-item label="项目委员会" prop="programCommittee">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5}"
              placeholder="请输入项目委员会"
              v-model="form.programCommittee"/>
          </el-form-item>

          <el-form-item label="技术委员会" prop="internationalTechnicalCommittee">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5}"
              placeholder="请输入技术委员会"
              v-model="form.internationalTechnicalCommittee"/>
          </el-form-item>

          <el-form-item label="征稿主题" prop="callPapers">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5}"
              placeholder="请输入技术委员会"
              v-model="form.callPapers"/>
          </el-form-item>
        </div>

      </el-form>
    </el-dialog>
    <!-- 弹窗区域结束 -->

    <!-- 表头按钮区域开始 -->
    <div class="manage-header">
      <el-button size="mini" class="el-icon-search" type="success" @click="searchClick" plain>搜索</el-button>
      <el-button size="mini" class="el-icon-refresh-right" type="warning" @click="resetClick" plain>重置</el-button>
      <el-button size="mini" class="el-icon-download" type="info" @click="exportClick" plain>导出</el-button>
      <!--      <el-upload action="#" style="margin-left: 0.5vw"-->
      <!--                 :show-file-list="false"-->
      <!--                 :http-request="importClick">-->
      <!--        <el-button size="mini" class="el-icon-upload" type="info" plain>导入</el-button>-->
      <!--      </el-upload>-->
    </div>
    <!-- 表头按钮区域结束 -->

    <!-- 表格 -->
    <el-table
      :data="pageData.list"
      style="width: 100%;margin-bottom: 20px;background-color: #ededed"
      row-key="id"
      border
      v-loading="tableLoad">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="identifying" label="网站标识"/>
      <el-table-column prop="priceTypeText" label="价格类型"/>
      <el-table-column prop="titleCn" label="网站标题"/>
      <el-table-column prop="domain" label="网站域名">
        <template v-slot="{row}">
          <a :href="`http://${row.domain}/`" target="_blank">{{ row.domain }}</a>
        </template>
      </el-table-column>
      <el-table-column prop="submissionDeadline" label="截稿日期"/>
      <el-table-column prop="teacherText" label="归属"/>
      <el-table-column prop="visits" label="浏览量"/>
      <el-table-column prop="statusText" label="状态"/>
      <el-table-column prop="createTime" label="创建时间"/>
      <el-table-column prop="updateTime" label="更新时间"/>
      <el-table-column label="操作">
        <template v-slot="{row}">
          <el-button size="mini" @click="editClick(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div>
      <el-pagination
        layout="prev, pager, next"
        :total="pageData.total"
        @current-change="pagingClick"/>
    </div>
  </div>
</template>

<script>

import '@/assets/static/css/base.css'
import service from '@/service/Business/WebsiteService'

export default service
</script>

<style>
.footer {
  width: 100%;
  display: flex;
  margin: 1vw 0 1vw 0;
  justify-content: space-between;
}
</style>
